import { MediaProvider, SpreakerSearchResult, Episode } from "src/MediaProvider";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Observable, ReplaySubject } from "rxjs";
import { PlayerController } from "../../PlayerController";
import { MatSnackBar } from "@angular/material";
import { SpreakerAPI, SpreakerWidget } from "../../models/Spreaker";

declare const SP: SpreakerAPI;

class SpreakerPlayerController extends PlayerController {
    public oncanplay = new ReplaySubject<void>();
    public currentTime: Observable<number>;
    public duration: Observable<number>;
    private widget: SpreakerWidget;
    constructor(element: HTMLAnchorElement) {
        super("https://widget.spreaker.com/widgets.js");
        this.onAPILoad.subscribe(() => {
            this.widget = SP.getWidget(element.id);
            const interval = setInterval(() => {
                const worked = this.widget.getState(item => {
                    this.episode.next({
                        id: item.episode_id.toString(),
                        title: item.title,
                        duration: Math.ceil(item.duration / 1000),
                        image: item.image_url,
                        datePublished: new Date(item.published_at)
                    } as Episode);
                });
                if (worked !== false) { clearInterval(interval); }
            }, 100);
            this.oncanplay.next();
            this.currentTime = Observable.create(observer => {
                this.widget.getPosition(p => observer.next(Math.round(p / 1000)));
            });
            this.duration = Observable.create(o => {
                this.widget.getDuration(d => o.next(d / 1000));
            });
        });
    }


    public play() {
        this.widget.play();
    }
    public pause() {
        this.widget.pause();
    }
    public toggle() {
        this.widget.getState((ep, st, isPlaying) => {
            isPlaying ? this.pause() : this.play();
        });
    }
    public goTo(seconds: number) {
        const worked = this.widget.seek(seconds * 1000);
        if (!worked) {
            setTimeout(() => this.goTo(seconds), 10);
        }
    }
}

export class Spreaker implements MediaProvider {
    public name = "Spreaker";
    public showInteractionWarning = true;
    public bannerImg = "/assets/images/spreaker-banner.png";
    public iconImg = "/assets/images/spreaker-icon.png";
    private searchUrl = "https://api.spreaker.com/v2/search?type=episodes&q=";

    constructor(private http: HttpClient, private snackbar: MatSnackBar) { }

    public createPlayerController(element: HTMLAnchorElement): PlayerController {
        return new SpreakerPlayerController(element);
    }
    public search(query: string): Observable<Episode[]> {
        return this.http.get<SpreakerSearchResult>(`${this.searchUrl}${query}`).pipe(
            map(result => result.response.items.map(
                item =>
                    ({
                        id: item.episode_id.toString(),
                        title: item.title,
                        duration: Math.ceil(item.duration / 1000),
                        image: item.image_url,
                        datePublished: new Date(item.published_at),
                        show: {
                            title: item.show.title
                        }
                    } as Episode)
            ))
        );
    }
}
