import { Component, OnInit, Inject, NgZone } from "@angular/core";
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from "@angular/material";
import { NgForm } from "@angular/forms";
import { MediaProvider, Episode } from "src/MediaProvider";
import { Spreaker } from "../MediaProviders/Spreaker";
import { HttpClient } from "@angular/common/http";
import { MediaProviderSearcherComponent } from "../media-provider-searcher/media-provider-searcher.component";
import { FileUrl } from "../MediaProviders/FileUrl";
import { YouTube } from "../MediaProviders/YouTube";
import { SoundCloud } from "../MediaProviders/SoundCloud";
import { PlayerController } from "src/PlayerController";

@Component({
    selector: "app-new-note-dialog",
    templateUrl: "./new-note-dialog.component.html",
    styleUrls: ["./new-note-dialog.component.less"]
})
export class NewNoteDialogComponent implements OnInit {
    public file: File;
    public selectedProvider: MediaProvider;
    public selectedEpisodeId: string;
    public noteName: string;
    public folderName: string;
    public user: firebase.User;
    public mediaProviders = {
        spreaker: new Spreaker(this.http, this.snackbar),
        fileUrl: new FileUrl(),
        youtube: new YouTube(this.http),
        soundcloud: new SoundCloud(),
    };
    constructor(
        public zone: NgZone,
        public dialogRef: MatDialogRef<NewNoteDialogComponent>,
        private snackbar: MatSnackBar,
        private http: HttpClient,
        @Inject(MAT_DIALOG_DATA) public folders: string[]
    ) {
        if (folders.length === 1) {
            this.folderName = folders[0];
        }
    }

    ngOnInit() { }

    public onUpload(event, form: NgForm) {
        this.file = event.target.files[0];
        this.submit(form);
    }

    public onMediaProviderWithoutSearchSave(mediaProvider: MediaProvider, event) {
        this.selectedProvider = mediaProvider;
        return this.dialogRef.close(this);
    }

    public onMediaProviderSearcherSave(info: MediaProviderSearcherComponent) {
        this.selectedProvider = info.mediaProvider;
        this.noteName = info.title;
        this.folderName = info.selectedFolder;
        this.dialogRef.close(this);
        this.selectedEpisodeId = info.selected.id;
    }

    public onPlayerControllerReady(pc: PlayerController) {
        pc.episode.subscribe(ep => {
            this.zone.run(() => {
                if (ep.title && !this.noteName) { this.noteName = ep.title; }
                if (ep.show && !this.folderName) { this.folderName = ep.show.title; }
            });
        });
    }

    public submit(form?: NgForm) {
        if (!this.file && !this.selectedEpisodeId) { return; }
        if (!this.selectedProvider && this.selectedEpisodeId) {
            this.selectedProvider = new FileUrl();
        }
        if (!form.valid) {
            return this.snackbar.open(
                "⚠️ There were some form errors. Please correct all form errors before submitting.",
                null,
                { duration: 5000 }
            );
        }
        this.dialogRef.close(this);
    }
}
