import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Episode, MediaProvider } from "src/MediaProvider";
import { PlayerController } from "src/PlayerController";

declare const YT;
declare const ytApisubscribers: Function[];
const onPlayReady = new Subject<any>();
const onAPIReady = new Subject<void>();
let youtubeApi;
let youtubeApi2;
class YouTubePlayerController extends PlayerController {
    public currentTime: Observable<number>;
    public duration: Observable<number>;
    constructor(private playerElement: HTMLDivElement, private videoID: string) {
        super("https://www.youtube.com/iframe_api");
        const sub = onAPIReady.subscribe(() => {
            sub.unsubscribe();
            const id = this.playerElement.id;
            youtubeApi = new YT.Player(id, {
                height: "390",
                width: "100%",
                videoId: videoID,
                playerVars: { autoplay: 0, controls: 1, modestbranding: 1, rel: 0, showinfo: 0 },
                events: {
                    onReady: p => {
                        // `this` doesn't work! It's a fake!
                        youtubeApi2 = p;
                        p.target.seekTo(0);
                        onPlayReady.next(p);
                        const videoData = youtubeApi2.target.getVideoData();
                        this.episode.next({
                            title: videoData.title,
                            id: videoData.id.videoId,
                            duration: videoData.getDuration(),
                        } as Episode);
                    },
                    onStateChange: p => {
                        if (p.data === YT.PlayerState.ENDED) {
                            // on end implementation goes here
                        }
                        onPlayReady.next(p);
                    },
                }
            });
        });
        // 1. API Loads Event (this class doesn't use it)
        // 2. API Ready Event
        // 3. API Play Ready Event (player is now playable)
        if (ytApisubscribers) {
            ytApisubscribers.push(() => onAPIReady.next());
        } else {
            onAPIReady.next();
        }
        this.duration = Observable.create(o => {
            o.next(Math.floor(youtubeApi2.target.getDuration()));
        });
        this.currentTime = Observable.create(o => {
            o.next(Math.floor(youtubeApi2.target.getCurrentTime()));
        });
        onPlayReady.subscribe(() => { this.oncanplay.next(); });
    }
    public play() {
        youtubeApi2.target.playVideo();
    }
    public pause() {
        youtubeApi2.target.pauseVideo();
    }
    public toggle() {
        const isPlaying = youtubeApi2.target.getPlayerState() === 1;
        isPlaying ? this.pause() : this.play();
    }
    public goTo(seconds: number) {
        youtubeApi2.target.seekTo(seconds);
    }
}

export class YouTube implements MediaProvider {
    private apiKey = environment.youtubeApiKey;
    public name = "YouTube";
    public iconImg = "/assets/images/youtube-icon.png";
    public bannerImg = "/assets/images/youtube-banner.png";
    public showInteractionWarning = false;
    constructor(private http: HttpClient) { }
    public search?(query: string): Observable<Episode[]> {
        // https://developers.google.com/youtube/v3/docs/#Search
        const uri = "https://www.googleapis.com/youtube/v3/search";
        const params = [
            ["key", this.apiKey],
            ["part", "snippet"],
            ["q", query],
            ["type", "video"],
        ].map(x => x.join("=")).join("&");

        return this.http.get<any>(`${uri}?${params}`).pipe(map(x => {
            const items = x.items
                .map(item => {
                    return {
                        id: item.id.videoId,
                        description: item.snippet.description,
                        title: item.snippet.title,
                        image: item.snippet.thumbnails.medium.url,
                        datePublished: new Date(item.snippet.publishedAt),
                        show: { title: item.snippet.channelTitle }
                    } as Episode;
                });
            return items;
        }));
    }
    public createPlayerController(element: HTMLElement): PlayerController {
        return new YouTubePlayerController(element as HTMLDivElement, element.getAttribute("video-id"));
    }
}

/**
 * YouTube API requires this but I don"t really need it.
 */
