import { MediaProvider } from "src/MediaProvider";
import { PlayerController } from "src/PlayerController";
import { Observable, Subject } from "rxjs";

export class AudioPlayerController extends PlayerController {
    public episodeId: string;
    public currentTime: Observable<number>;
    public duration: Observable<number>;
    constructor(private element: HTMLAudioElement) {
        super();
        this.oncanplay = Observable.create(o => (element.oncanplay = () => o.next()));
        this.duration = Observable.create(o => o.next(Math.floor(element.duration)));
        this.currentTime = Observable.create(o => o.next(Math.floor(element.currentTime)));
    }
    public play() {
        this.element.play();
    }
    public pause() {
        this.element.pause();
    }
    public toggle() {
        this.element.paused ? this.play() : this.pause();
    }
    public goTo(seconds: number) {
        this.element.currentTime = seconds;
    }
}

export class AudioFileProvider implements MediaProvider {
    public name = "file";
    public bannerImg: string;
    public iconImg: string;
    public showInteractionWarning = false;
    public createPlayerController(element: HTMLAudioElement): PlayerController {
        return new AudioPlayerController(element);
    }
}
