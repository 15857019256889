import { Injectable } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";

@Injectable({ providedIn: "root" })
export class PageInfoService {

    constructor(
        private titleService: Title,
        private metaService: Meta,
    ) { }
    public setPageAndTitle(note: Note) {
        const pageTitle = `${note.title} - Media Notes`;
        const pageDescription = this.getDescription(note);
        this.titleService.setTitle(pageTitle);
        this.metaService.addTags([{
            name: "og:title",
            content: pageTitle,
        }, {
            name: "twitter:title",
            content: pageTitle,
        }, {
            name: "twitter:description",
            content: pageDescription,
        }, {
            name: "og:description",
            content: pageDescription,
        }, {
            name: "og:url",
            content: `https://www.medianotes.app/published/${note.id}`
        }]);
    }

    private getDescription(note: Note) {
        return Object
            .values(note.notes)
            .slice(1)
            .join(" ");
    }
}
