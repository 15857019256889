import { Component, OnInit, ElementRef, Input, ViewChild, OnChanges, Output, EventEmitter } from "@angular/core";
import { GeneralPlayerComponent } from "src/models/GeneralPlayerComponent";
import { PlayerController } from "src/PlayerController";
import { Observable, Subject } from "rxjs";
import { YouTube } from "../MediaProviders/YouTube";
import { HttpClient } from "@angular/common/http";
import { Episode } from "src/MediaProvider";

@Component({
    selector: "app-youtube-player",
    templateUrl: "./youtube-player.component.html",
    styleUrls: ["./youtube-player.component.less"]
})
export class YoutubePlayerComponent implements GeneralPlayerComponent, OnChanges, OnInit {
    @Output() public episode: EventEmitter<Partial<Episode>>;
    @Input() public episodeId: string;
    public oncanplay = new Subject<void>();
    public controller: PlayerController;

    @ViewChild("player") private player: ElementRef;
    private youtube = new YouTube(this.http);

    constructor(private http: HttpClient) { }

    public ngOnInit() {
        this.player.nativeElement.setAttribute("video-id", this.episodeId);
        this.controller = this.youtube.createPlayerController(this.player.nativeElement);
        this.controller.episode.subscribe((episode) => this.episode.next(episode));
        this.controller.oncanplay.subscribe(() => {
            this.oncanplay.next();
        });
    }
    public ngOnChanges() {
        // this.ngOnInit();
    }

}
