import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatTableDataSource, MatSnackBar, MatSort } from "@angular/material";
import { NewNoteDialogComponent } from "../new-note-dialog/new-note-dialog.component";
import { NotesDatabaseService } from "../notes-database.service";
import { AngularFireAuth } from "@angular/fire/auth";
import * as firebase from "firebase";
import { sbErrorHandler } from "../sb-error-handler";
import { ActivatedRoute } from "@angular/router";
import { Spreaker } from "../MediaProviders/Spreaker";
import { HttpClient } from "@angular/common/http";
import { YouTube } from "../MediaProviders/YouTube";
import { FileUrl } from "../MediaProviders/FileUrl";
import { SoundCloud } from "../MediaProviders/SoundCloud";
@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.less"]
})
export class HomeComponent implements OnInit {
    /** The user's notes */
    public notes: MatTableDataSource<NoteRecord> = new MatTableDataSource();
    /** The user's folders */
    public folders: string[];
    /** The user's username */
    public userName: string;
    /** The width of the new-note-dialog */
    private dialogWidth = "600px";
    /** The current upload percent of the new note (if it's a file) */
    public uploadPercent: number;
    /** The Google authentication provider (to sign in with Google) */
    private authProvider = new firebase.auth.GoogleAuthProvider();
    /** The currently selected folder. */
    public selectedFolder: string;
    /**  */
    public percentComplete: number;
    /** Whether the data is currently loading or not */
    public finishedLoading = false;
    public mediaProviders = {
        Spreaker: new Spreaker(this.http, this.snackbar),
        YouTube: new YouTube(this.http),
        SoundCloud: new SoundCloud(),
        "File URL": new FileUrl(),
    };
    constructor(
        private dialog: MatDialog,
        private notesService: NotesDatabaseService,
        private afAuth: AngularFireAuth,
        private snackbar: MatSnackBar,
        private http: HttpClient,
        private route: ActivatedRoute
    ) { }

    public ngOnInit() {
        this.route.queryParams.subscribe(q => {
            this.selectedFolder = q.folder;
            this.notes.data = [];
            if (!this.selectedFolder) {
                this.percentComplete = null;
            }
            this.afAuth.user.subscribe(user => {
                if (!user) {
                    this.userName = null;
                    return;
                }
                this.userName = user.displayName;
                this.notesService.getFolderNotes(this.selectedFolder).subscribe(
                    notes => {
                        const oldCount = notes.length;
                        notes = notes.filter(n => !n.media || this.mediaProviders[n.media.provider] != null);
                        const newCount = notes.length;
                        if (oldCount > newCount) {
                            this.snackbar.open(
                                `⚠️ ${oldCount - newCount} note(s) not shown due to unsupported media types.`,
                                null,
                                { duration: 5_000 }
                            );
                        }
                        this.notes.data = notes;
                        if (this.selectedFolder) {
                            this.percentComplete = Math.ceil(
                                (notes.map(n => n.percentComplete).reduce((a, b) => (a += b)) / (notes.length * 100)) *
                                100
                            );
                        }
                    },
                    error => sbErrorHandler(this.snackbar, error)
                );
                this.notesService.userFolders.subscribe(folders => (this.folders = Array.from(new Set(folders))));
            });
        });

    }

    public async signIn() {
        this.afAuth.auth.signInWithPopup(this.authProvider);
    }
    public async signOut() {
        if (confirm("Sign out?")) {
            this.afAuth.auth.signOut();
        }
    }
    public onNewNoteClick() {
        this.dialog
            .open(NewNoteDialogComponent, {
                width: this.dialogWidth,
                data: this.selectedFolder ? [this.selectedFolder] : Array.from(this.folders)
            })
            .afterClosed()
            .subscribe((x: NewNoteDialogComponent) => {
                if (!x) { return; }
                const newNote = {
                    folder: x.folderName,
                    title: x.noteName,
                    dateCreated: new Date() as any,
                    dateUpdated: new Date() as any,
                    notes: { 0: [`# ${x.noteName}`] },
                    lastTimeStamp: 0,
                    percentComplete: 0,
                    timeStampDelay: 0,
                } as NoteRecord;
                // if the user used a media provider
                if (x.selectedProvider) {
                    newNote.media = {
                        id: x.selectedEpisodeId,
                        provider: x.selectedProvider.name
                    };
                }
                this.notesService.createNote(newNote, x.file).subscribe(uploadTask => {
                    if (!uploadTask) { return; }
                    uploadTask.percentageChanges().subscribe(perc => (this.uploadPercent = perc));
                    uploadTask.then(() => (this.uploadPercent = null), error => sbErrorHandler(this.snackbar, error));
                }, error => sbErrorHandler(this.snackbar, error));
            }, error => sbErrorHandler(this.snackbar, error));
    }
}
