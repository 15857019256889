import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { NotesDatabaseService } from "../notes-database.service";
import { ActivatedRoute } from "@angular/router";
import { PlayerController } from "src/PlayerController";
import { MediaProviderFactory } from "src/MediaProviderFactory";
import { HttpClient } from "@angular/common/http";
import { MatSnackBar } from "@angular/material";
import { MediaProvider } from "src/MediaProvider";
import { Title, Meta } from "@angular/platform-browser";
import { PageInfoService } from "../page-info.service";

@Component({
    selector: "app-published-note",
    templateUrl: "./published-note.component.html",
    styleUrls: ["./published-note.component.less"]
})
export class PublishedNoteComponent implements OnInit, OnDestroy {
    public id: string;
    public publishedNote: PublishedNoteRecord;
    public mediaProvider: MediaProvider;
    private playerController: PlayerController;
    public episodeId: string;
    public currentTime: number;
    public autoScroll = true;
    public intervalForCurrentTime: number;
    public isFullScreen = false;
    @ViewChild("container") public container;
    constructor(
        private notesService: NotesDatabaseService,
        private ar: ActivatedRoute,
        private httpClient: HttpClient,
        private sb: MatSnackBar,
        private pageInfo: PageInfoService,
    ) {
        document.onfullscreenchange = () => {
            this.isFullScreen = !this.isFullScreen;
        };
    }
    public ngOnDestroy(): void {
        window.clearInterval(this.intervalForCurrentTime);
    }
    public ngOnInit() {
        this.ar.params.subscribe(params => {
            this.id = params.id;
            this
                .notesService
                .getPublished(params.id)
                .valueChanges()
                .subscribe(publishedNote => {
                    this.pageInfo.setPageAndTitle(publishedNote);
                    this.publishedNote = publishedNote;
                    if (!this.publishedNote.media) {
                        this
                            .notesService
                            .getAudioFileUrlForNote(publishedNote, publishedNote.ownerId)
                            .subscribe(url => {
                                this.episodeId = url;
                                this.mediaProvider = MediaProviderFactory("file",
                                    this.httpClient,
                                    this.sb);
                            });
                    } else {
                        this.episodeId = publishedNote.media.id;
                        this.mediaProvider = MediaProviderFactory(
                            publishedNote.media.provider,
                            this.httpClient,
                            this.sb,
                        );
                        this.mediaProvider.showInteractionWarning = false;
                    }
                });
        });
    }
    public onPlayerControllerReady(playerController: PlayerController) {
        this.playerController = playerController;
        if (this.intervalForCurrentTime != null) {
            return;
        }
        this.intervalForCurrentTime = window.setInterval(() => {
            this.updateCurrentTime();
        }, 500);
    }
    private updateCurrentTime() {
        this.playerController.currentTime.subscribe(currentTime => {
            this.currentTime = currentTime;
        });
    }

    public onTimeStampClick(ts: number) {
        this.playerController.goTo(ts);
        this.playerController.play();
        this.updateCurrentTime();
    }

    public onEmbedClick() {
        prompt(
            "Paste the following code into your website:",
            `<iframe src="https://www.medianotes.app/published/${this.publishedNote.id}" allowfullscreen style="height: 75vh; width: 100%"></iframe>`
        );
    }

    public async onFullscreenClick() {
        const element = this.container._element.nativeElement;
        if (!this.isFullScreen) {
            if (element.requestFullscreen) {
                await element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                await element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
                await element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) {
                await element.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                await document.exitFullscreen();
            } else if (document["msExitFullscreen"]) {
                await document["msExitFullscreen"]();
            }
        }
    }
}
