import { Component, OnInit } from "@angular/core";

// For Youtube API
function onYouTubeIframeAPIReady() { alert(); }
@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.less"]
})
export class AppComponent {
    public title = "audio-note";
}
