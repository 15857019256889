import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "minutesSeconds"
})
export class MinutesSecondsPipe implements PipeTransform {
    public transform(totalSeconds: number) {
        const hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;

        const hrsStr = hours ? `${this.pad(hours)}:` : "";
        const minStr = `${this.pad(minutes)}:`;
        const secStr = this.pad(seconds);

        return `${hrsStr}${minStr}${secStr}`;
    }
    private pad(number: number) {
        return String(number).padStart(2, "0");
    }
}
