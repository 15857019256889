export const environment = {
    firebase: {
        apiKey: "AIzaSyBkIQ2jbJxKvYRCPTvQVUlu9Y06MwoDGl4",
        authDomain: "audio-note-b5b3f.firebaseapp.com",
        databaseURL: "https://audio-note-b5b3f.firebaseio.com",
        projectId: "audio-note-b5b3f",
        storageBucket: "audio-note-b5b3f.appspot.com",
        messagingSenderId: "550349887531",
        appId: "1:550349887531:web:6c7e9b651db1cb88"
    },
    production: false,
    youtubeApiKey: "AIzaSyAbrbZR3_tpbLWgWCnOxWc0oq3PB9cYnyk"
};
