import { Component, OnInit, ViewChild, ElementRef, OnChanges, Input, EventEmitter, Output } from "@angular/core";
import { GeneralPlayerComponent } from "src/models/GeneralPlayerComponent";
import { PlayerController } from "src/PlayerController";
import { Subject } from "rxjs";
import { SoundCloud } from "../MediaProviders/SoundCloud";
import { SafeUrl, DomSanitizer } from "@angular/platform-browser";
import { Episode } from "src/MediaProvider";

@Component({
    selector: "app-soundcloud-player",
    templateUrl: "./soundcloud-player.component.html",
    styleUrls: ["./soundcloud-player.component.less"]
})
export class SoundcloudPlayerComponent implements OnInit, OnChanges, GeneralPlayerComponent {
    /** A soundcloud regular url */
    @Input() public episodeId: string;
    @ViewChild("player") private player: ElementRef<HTMLIFrameElement>;
    @Output() public episode = new EventEmitter<Partial<Episode>>();
    private soundcloud: SoundCloud;
    public controller: PlayerController;
    public oncanplay = new Subject<void>();
    constructor(private sanitizer: DomSanitizer) { }
    public ngOnInit() {
        if (!this.episodeId) { return; }
        const source = `https://w.soundcloud.com/player/?url=${this.episodeId}`;
        this.player.nativeElement.setAttribute("src", source);
        this.soundcloud = new SoundCloud();
        this.controller = this.soundcloud.createPlayerController(this.player.nativeElement);
        this.controller.episode.subscribe((episode) => this.episode.next(episode));
        this.controller.oncanplay.subscribe(() => this.oncanplay.next());
    }
    public ngOnChanges() {
        this.ngOnInit();
    }
}
