import {
    Component,
    OnInit,
    Input,
    ViewChild,
    AfterViewChecked,
    Output,
    EventEmitter,
} from "@angular/core";
import { PlayerController } from "src/PlayerController";
import { MediaProvider } from "src/MediaProvider";
import { MatSnackBar } from "@angular/material";
import { GeneralPlayerComponent } from "src/models/GeneralPlayerComponent";

@Component({
    selector: "app-player",
    templateUrl: "./player.component.html",
    styleUrls: ["./player.component.less"]
})
export class PlayerComponent implements OnInit, AfterViewChecked {
    @Input() public episodeId: string;
    @Input() public mediaProvider: MediaProvider;
    @Input() public showInteractionWarning = true;
    @Output() public playerController = new EventEmitter<PlayerController>();
    @ViewChild("player") private player: GeneralPlayerComponent;

    constructor(private snackbar: MatSnackBar) { }

    public ngOnInit() {
        if (this.showInteractionWarning && this.mediaProvider.showInteractionWarning) {
            const warning = "⚠️ Click anywhere inside the player to activate keyboard shortcut keys.";
            const sb = this.snackbar.open(warning, "Dismiss", { horizontalPosition: "right", verticalPosition: "top" });
            sb.onAction().subscribe(x => sb.dismiss);
        }
    }
    public ngAfterViewChecked() {
        this.playerController.emit(this.player.controller);
    }
}
