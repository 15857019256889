import { Component, OnInit, Input, ViewChild, EventEmitter } from "@angular/core";
import { GeneralPlayerComponent } from "src/models/GeneralPlayerComponent";
import { PlayerController } from "src/PlayerController";
import { Observable } from "rxjs";
import { AudioFileProvider } from "../MediaProviders/AudioFileProvider";
import { Episode } from "src/MediaProvider";

@Component({
    selector: "app-audio-file-player",
    templateUrl: "./audio-file-player.component.html",
    styleUrls: ["./audio-file-player.component.less"]
})
export class AudioFilePlayerComponent implements OnInit, GeneralPlayerComponent {
    public episode: EventEmitter<Partial<Episode>> = new EventEmitter<Partial<Episode>>();
    @Input() public url: string;
    @ViewChild("player") private player;
    public controller: PlayerController;
    public oncanplay: Observable<void>;
    private audioFileProvider = new AudioFileProvider();

    constructor() { }

    ngOnInit() {
        this.controller = this.audioFileProvider.createPlayerController(this.player.nativeElement);
    }
}
