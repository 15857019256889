import { Component, OnInit, HostListener, AfterViewChecked, NgZone, ApplicationRef } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { ActivatedRoute } from "@angular/router";
import { NotesDatabaseService } from "../notes-database.service";
import { MatSnackBar } from "@angular/material";
import { sbErrorHandler } from "../sb-error-handler";
import { PlayerController } from "src/PlayerController";
import { MediaProviderFactory } from "src/MediaProviderFactory";
import { MediaProvider } from "src/MediaProvider";
import { HttpClient } from "@angular/common/http";
import { MinutesSecondsPipe } from "../minutes-seconds.pipe";
import { switchMap } from "rxjs/operators";
import { ThrowStmt } from "@angular/compiler";

@Component({
    selector: "app-note",
    templateUrl: "./note-editor.component.html",
    styleUrls: ["./note-editor.component.less"]
})
export class NoteEditorComponent implements OnInit, AfterViewChecked {
    public audioUrl: string;
    public note: NoteRecord;
    public episodeId: string;
    public cols = 92;
    public showMarkdown = true;
    public initialFlattenedNotes = [];
    public openSideNav = false;
    public mediaProvider: MediaProvider;
    public isPublished = false;
    private lastTsSet = false;
    private seekTime = 5;
    private playerController: PlayerController;
    private mouseDownTimeout: number;
    private minutesSecondsPipe = new MinutesSecondsPipe();

    constructor(
        private notesService: NotesDatabaseService,
        private ar: ActivatedRoute,
        private snackbar: MatSnackBar,
        private http: HttpClient,
        private zone: NgZone
    ) { }
    public ngOnInit() {
        document.execCommand("defaultParagraphSeparator", false, "p");
        this.ar.params
            .pipe(switchMap(params => this.notesService.getUserNote(params.id)))
            .subscribe(noteRecord => {
                if (this.note != null) return;
                this.note = noteRecord;
                this.setMediaProviderAndEpisodeId();
                this.setFlattenedNotes();
                this.setPublishedListener(noteRecord);
            })
    }
    public onTimeStampMouseDown(ts: number) {
        this.mouseDownTimeout = window.setTimeout(() => this.editTimeStamp(ts), 500);
    }
    public onTimeStampMouseUp(ts: number) {
        window.clearTimeout(this.mouseDownTimeout);
        this.setAudioTime(ts, true);
    }
    /** The timestamps of the notes. */
    public get timestamps() {
        if (!this.note) {
            return [];
        }
        const ts = Object.keys(this.note.notes).map(x => Number(x));
        return ts;
    }
    private setPublishedListener(noteRecord: NoteRecord) {
        try {
            this
                .notesService
                .getPublished(noteRecord.id)
                .valueChanges()
                .subscribe(publishedNote => {
                    this.isPublished = publishedNote != null;
                });
        }
        catch (e) { }
    }
    private setFlattenedNotes() {
        for (const timestamp in this.note.notes) {
            if (this.note.notes.hasOwnProperty(timestamp)) {
                for (let i = 0; i < this.note.notes[timestamp].length; i++) {
                    this.initialFlattenedNotes.push({
                        timestamp: Number(timestamp),
                        paragraphNum: i,
                        text: this.note.notes[timestamp][i]
                    });
                }
            }
        }
    }
    private setMediaProviderAndEpisodeId() {
        if (!this.note.media) {
            this.notesService.getAudioFileUrlForNote(this.note).subscribe(url => {
                this.episodeId = url;
                this.mediaProvider = MediaProviderFactory("file", this.http, this.snackbar);
            });
        }
        else {
            this.mediaProvider = MediaProviderFactory(this.note.media.provider, this.http, this.snackbar);
            this.episodeId = this.note.media.id;
        }
    }
    public async onPublishClick() {
        let warningMessage = `Are you sure you want to publish "${this.note.title}"?`;
        if (this.isPublished) {
            warningMessage = [
                "Warning. This note has already been published.",
                "Would you like to republish it with your latest changes?"
            ].join(" ");
        }
        const confirmed = window.confirm(warningMessage);
        if (!confirmed) { return; }
        const sb = this.snackbar.open("⏱ Publishing...");
        this.notesService.publishNote(this.note).subscribe(() => {
            window.open(`/published/${this.note.id}`);
            sb.dismiss();
        });
    }
    public async onUnpublishClick() {
        const confirmed = window.confirm("⚠️ Unpublish this media note?");
        if (!confirmed) { return; }
        this.snackbar.open("⏱ Unpublishing note.");
        try {
            await this.notesService.deletePublishedNote(this.note);
            const sb = this.snackbar.open("✅ Note unpublished.", "Dismiss", { duration: 7000 });
            sb.onAction().subscribe(() => sb.dismiss());
        } catch (e) {
            this.errorHandler(e);
        }
    }
    public onViewPublishedClick() {
        window.open(`/published/${this.note.id}`);
    }
    public updateNoteAtTimestampAndView(timestamp: number, value: any, paragraphNum?: number) {
        return this.zone.run(() => {
            if (paragraphNum == null) {
                this.note.notes[timestamp] = value;
            } else {
                this.note.notes[timestamp][paragraphNum] = value;
            }
        });
    }
    public ngAfterViewChecked() {
        this.resizeTimestamps();
    }
    public onPlayerControllerReady(controller: PlayerController) {
        this.playerController = controller;
        this.playerController.oncanplay.subscribe(() => {
            if (!this.lastTsSet) {
                this.setAudioTime(this.note.lastTimeStamp);
                this.lastTsSet = true;
            }
        });
    }
    public editTimeStamp(oldTs: number) {
        let newTsRaw: string;
        if (oldTs === 0) {
            return alert(
                "You cannot edit the timestamp at 00:00. Try inserting a new paragraph at the desired time instead."
            );
        }
        const errorMessage = [
            "The format of that timestamp was invalid.",
            "Please use the format MM:SS or HH:MM:SS."
        ].join(" ");
        const allTs = this.timestamps;
        const indexOfOldTs = this.timestamps.indexOf(oldTs);
        const timeStamps = {
            all: allTs,
            min: this.timestamps[indexOfOldTs - 1],
            max: this.timestamps[indexOfOldTs + 1],
            new: null,
            old: oldTs
        };
        const minutesSeconds = this.minutesSecondsPipe.transform(oldTs);
        const friendly = {
            min: this.minutesSecondsPipe.transform(timeStamps.min),
            max: this.minutesSecondsPipe.transform(timeStamps.max),
        };
        let promptMessage = `Enter new timestamp between ${friendly.min} and ${friendly.max}:`;
        if (timeStamps.max == null) {
            promptMessage = `Enter new timestamp that is after ${friendly.min}:`;
        }
        newTsRaw = window.prompt(promptMessage, minutesSeconds);
        if (newTsRaw == null) { return; }
        timeStamps.new = getNewTs(newTsRaw);
        if (timeStamps.new == null) {
            alert(errorMessage);
            return this.editTimeStamp(timeStamps.old);
        }
        if (timeStamps.old === timeStamps.new) { return; }
        const isInRange = timeStamps.new >= timeStamps.min && timeStamps.new <= (timeStamps.max || Infinity);
        if (!isInRange) { return this.editTimeStamp(oldTs); }
        const oldParagraphs = getParagraphsAtTs(timeStamps.old);
        for (const p of oldParagraphs) {
            this.setParagraphElementTs(p, timeStamps.new);
        }
        const paragraphsAtNewTimeStamp = getParagraphsAtTs(timeStamps.new);
        paragraphsAtNewTimeStamp.forEach((p, i) => {
            p.dataset.paragraphnum = String(i);
        });
        this.updateNoteRecordsTimestamps();
        function getParagraphsAtTs(ts: number) {
            return Array.from(
                document.querySelectorAll(`.paragraph.timestamp-${ts}`)
            ) as HTMLElement[];
        }
        function getNewTs(input: string) {
            const sections = input.trim().split(":");
            if (sections.length === 2) { sections.unshift("00"); }
            const validNumOfSections = sections.length === 3;
            if (!validNumOfSections) { return null; }
            if (sections.some(s => s.trim() === "")) { return null; }
            const toNum = sections.map(s => Number(s));
            const isAllNumbers = toNum.every(s => !isNaN(s));
            if (!isAllNumbers) { return null; }
            const hhmmss = sections.map(s => Number(s));
            const inputTimeStamp = {
                hours: hhmmss[0],
                minutes: hhmmss[1],
                seconds: hhmmss[2],
            };
            const validHours = inputTimeStamp.hours >= 0;
            const validMinutes = inputTimeStamp.minutes >= 0 && inputTimeStamp.minutes < 60;
            const validSeconds = inputTimeStamp.seconds >= 0 && inputTimeStamp.seconds < 60;
            if (!validHours || !validMinutes || !validSeconds) { return null; }
            return (inputTimeStamp.hours * 60 * 60)
                + (inputTimeStamp.minutes * 60)
                + inputTimeStamp.seconds;
        }
    }
    /**
     * Runs when the user changes the content inside the editor.
     * @param event The event that happened
     */
    public onChange(event) {
        switch (event.inputType) {
            case "insertText":
                if (event.data == null) {
                    // Then they user actually inserted a paragraph. Perhaps this is a browser bug.
                    return this.onInsertParagraph(event);
                }
                break;
            case "insertParagraph":
                event.preventDefault();
                this.onInsertParagraph(event);
                break;
            case "deleteContentBackward":
                /** The timestamp the user removed. */
                const removedTs = this.timestamps.find(ts => {
                    const included = !this.paragraphElements.map(x => x.dataset.timestamp).includes(String(ts));
                    return included;
                });

                if (removedTs == null) {
                    // find the deleted paragraph
                    for (const ts of this.timestamps) {
                        for (const pNum in this.note.notes[ts]) {
                            if (this.note.notes[ts][pNum] != null) {
                                const deleted = !this.paragraphElements.some(
                                    p => p.dataset.timestamp === String(ts) && p.dataset.paragraphnum === String(pNum)
                                );
                                if (deleted) {
                                    // delete the deleted paragraph from this.note
                                    this.note.notes[ts].splice(Number(pNum), 1);
                                    break;
                                }
                            }
                        }
                    }
                } else {
                    this.updateNoteRecordsTimestamps();

                    // remove timestamp HTMLElement
                    const timestampElement = document.getElementById(`timestamp-${removedTs}`);
                    timestampElement.parentElement.removeChild(timestampElement);
                }
                break;
        }

        this.resizeTimestamps();
    }
    private get insertedParagraphElement() {
        return this.paragraphElements.slice(1).find(
            p =>
                (p.previousSibling as HTMLElement).dataset.timestamp === p.dataset.timestamp && // same ts
                (p.previousSibling as HTMLElement).dataset.paragraphnum === p.dataset.paragraphnum // same num
        );
    }
    private onInsertParagraph(event) {
        const editorElement = this.insertedParagraphElement.parentElement; // the editor playground the user can type in
        const isLastPElement = editorElement.lastChild === this.insertedParagraphElement;

        this.playerController.currentTime.subscribe(currentTs => {
            currentTs -= Math.max(0, this.note.timeStampDelay);
            console.log({ currentTs });
            if (isLastPElement) {
                const previousPTs = Number(this.insertedParagraphElement.dataset.timestamp);

                if (currentTs > previousPTs) {
                    this.updateNoteAtTimestampAndView(currentTs, [event.data || ""]);
                    this.setParagraphElementTs(this.insertedParagraphElement, currentTs);
                } else {
                    this.fixParagraphElementNumber(this.insertedParagraphElement);
                }
            } else {
                // the the user inserted a paragraph between other paragraphs
                const previousTimestamp = Number(
                    (this.insertedParagraphElement.previousSibling as HTMLElement).dataset.timestamp
                );
                const nextTs = Number((this.insertedParagraphElement.nextSibling as HTMLElement).dataset.timestamp);
                const shouldCreateNewTs = previousTimestamp < currentTs && nextTs > currentTs;
                if (shouldCreateNewTs) {
                    this.updateNoteAtTimestampAndView(currentTs, [event.data]);
                    this.setParagraphElementTs(this.insertedParagraphElement, currentTs);
                    this.fixParagraphElementNumber(this.insertedParagraphElement);
                    this.resizeTimestamps();
                } else {
                    this.fixParagraphElementNumber(this.insertedParagraphElement);
                }
            }
        });
    }
    private setParagraphElementTs(p: HTMLElement, ts: number, resetParagraphNumber = true) {
        this.zone.run(() => {
            const previousPTimestamp = Number(p.dataset.timestamp);
            p.classList.replace(`timestamp-${previousPTimestamp}`, `timestamp-${ts}`);
            p.dataset.timestamp = String(ts);
            if (resetParagraphNumber) { p.dataset.paragraphnum = "0"; }
        });
    }
    private fixParagraphElementNumber(element: HTMLElement) {
        const previousParagraph = element.previousElementSibling as HTMLElement;
        const previousPNum = Number(previousParagraph.dataset.paragraphnum);
        element.dataset.paragraphnum = String(previousPNum + 1);

        // increment next elements' number
        while (
            element.nextSibling &&
            Number((element.nextSibling as HTMLElement).dataset.timestamp) === Number(element.dataset.timestamp)
        ) {
            const nextSibling = element.nextSibling as HTMLElement;
            nextSibling.dataset.paragraphnum = String(Number(element.dataset.paragraphnum) + 1);
            element = nextSibling;
        }
    }
    /** All the note paragraph elements */
    private get paragraphElements(): HTMLDivElement[] {
        return Array.from(document.querySelectorAll("div.paragraph")) as HTMLDivElement[];
    }
    /**
     * repositions the timestamps shown on the left of the page
     */
    private resizeTimestamps() {
        for (const ts of this.timestamps) {
            const element = document.getElementById(`timestamp-${ts}`);
            const paragraphs = Array.from(document.querySelectorAll(`.timestamp-${ts}`)).filter(
                (x: any) => !x.__ng_removed
            );
            if (!element) {
                return; // sometime this happens but doesn't affect the view so who cares.
            }
            if (!paragraphs.length) {
                return;
            }
            const totalHeight = paragraphs
                .map(e => e.getBoundingClientRect().height)
                .reduce((p, c) => {
                    return (c += p);
                });
            element.style.height = `${totalHeight}px`;
        }
    }
    private updateNoteRecordsTimestamps() {
        // add necessary timestamps into this.note
        for (const paragraph of this.paragraphElements) {
            const timestamp = Number(paragraph.dataset.timestamp);
            const paragraphNum = Number(paragraph.dataset.paragraphnum);
            const text = paragraph.innerText;

            if (this.note.notes[timestamp] == null) {
                this.updateNoteAtTimestampAndView(timestamp, []);
            }
            this.updateNoteAtTimestampAndView(timestamp, text, paragraphNum);
        }

        // remove necessary timestamps in this.note
        // 1. Get all the timestamps to delete
        // 2. Delete them
        this.timestamps
            .filter(ts => !this.paragraphElements.map(p => p.dataset.timestamp).includes(String(ts)))
            .forEach(ts => delete this.note.notes[ts]);
    }
    @HostListener("keydown.control.s", ["$event"])
    public save($event: Event) {
        this.note = { ...this.note }; // force change detection for note-viewer
        this.snackbar.open("⏱ Saving...", null, {
            horizontalPosition: "end",
            verticalPosition: "bottom"
        });
        this.playerController.currentTime.subscribe(currentTs =>
            this.playerController.duration.subscribe(duration => {
                try {
                    this.updateNoteRecordsTimestamps();

                    // update last timestamp
                    this.note.lastTimeStamp = Math.floor(currentTs);

                    // update title
                    this.note.title = document.getElementById("note-title").innerText;

                    // update percentComplete
                    this.note.percentComplete = Math.ceil((Math.max(...this.timestamps) / duration) * 100);
                    // update db
                    this.notesService.updateNote(this.note).subscribe(
                        () =>
                            this.snackbar.open("✅ Saved.", null, {
                                duration: 3000,
                                horizontalPosition: "end",
                                verticalPosition: "bottom"
                            }),
                        error => this.errorHandler(error)
                    );
                } catch (e) {
                    alert(e);
                    console.error(e);
                    this.errorHandler(e);
                }
            })
        );
        if ($event) $event.preventDefault();
    }
    @HostListener("keydown.control.j", ["$event"])
    public rewind($event: Event) {
        $event.preventDefault();
        this.playerController.currentTime.subscribe(ct => this.setAudioTime(ct - this.seekTime));
    }
    @HostListener("keydown.control.l", ["$event"])
    public forward($event: Event) {
        $event.preventDefault();
        this.playerController.currentTime.subscribe(ct => this.setAudioTime(ct + this.seekTime));
    }
    public setAudioTime(ts: number, thenPlay?: boolean) {
        this.playerController.goTo(ts);
        if (thenPlay) {
            this.playerController.play();
        }
    }
    @HostListener("keydown.control.space", ["$event"])
    public togglePlay($event: Event) {
        $event.preventDefault();
        this.playerController.toggle();
    }
    private errorHandler(error) {
        const sb = this.snackbar.open(`❌ ${error}`, "Dismiss");
        sb.onAction().subscribe(() => sb.dismiss());
    }
}
