import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { environment } from "../environments/environment";
import { FlexLayoutModule } from "@angular/flex-layout";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import {
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatInputModule,
    MatDividerModule,
    MatCardModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTableModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatSortModule,
    MatSidenavModule,
    MatTabsModule
} from "@angular/material";
import { ShowdownModule } from "ngx-showdown";
import { HomeComponent } from "./home/home.component";
import { FormsModule } from "@angular/forms";
import { NewNoteDialogComponent } from "./new-note-dialog/new-note-dialog.component";
import { APP_BASE_HREF } from "@angular/common";
import { NotesDatabaseService } from "./notes-database.service";
import { MinutesSecondsPipe } from "./minutes-seconds.pipe";
import { NotesTableComponent } from "./notes-table/notes-table.component";
import { PlayerComponent } from "./player/player.component";
import { MediaProviderSearcherComponent } from "./media-provider-searcher/media-provider-searcher.component";
import { SpreakerPlayerComponent } from "./spreaker-player/spreaker-player.component";
import { HttpClientModule } from "@angular/common/http";
import { AudioFilePlayerComponent } from "./audio-file-player/audio-file-player.component";
import { YoutubePlayerComponent } from "./youtube-player/youtube-player.component";
import { SoundcloudPlayerComponent } from "./soundcloud-player/soundcloud-player.component";
import { PublishedNoteComponent } from "./published-note/published-note.component";
import { NoteViewerComponent } from "./note-viewer/note-viewer.component";
import { NoteEditorComponent } from "./note-editor/note-editor.component";

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        NoteEditorComponent,
        NewNoteDialogComponent,
        MinutesSecondsPipe,
        NotesTableComponent,
        PlayerComponent,
        MediaProviderSearcherComponent,
        SpreakerPlayerComponent,
        AudioFilePlayerComponent,
        YoutubePlayerComponent,
        SoundcloudPlayerComponent,
        PublishedNoteComponent,
        NoteViewerComponent,
    ],
    imports: [
        ShowdownModule.forRoot({ smoothLivePreview: true }),
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireStorageModule,
        AngularFirestoreModule,
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        MatToolbarModule,
        MatProgressBarModule,
        FormsModule,
        MatDividerModule,
        MatCardModule,
        FlexLayoutModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatSelectModule,
        MatSlideToggleModule,
        HttpClientModule,
        MatSortModule,
        MatListModule,
        MatDialogModule,
        MatTabsModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatTableModule,
        MatSidenavModule,
    ],
    providers: [{ provide: APP_BASE_HREF, useValue: "/" }, NotesDatabaseService],
    bootstrap: [AppComponent],
    entryComponents: [NewNoteDialogComponent]
})
export class AppModule { }
