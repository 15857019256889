import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from "@angular/core";

@Component({
    selector: "app-note-viewer",
    templateUrl: "./note-viewer.component.html",
    styleUrls: ["./note-viewer.component.less"]
})
export class NoteViewerComponent implements OnInit, OnChanges {
    @Input() public currentTimeStamp: number;
    @Input() public note: Note;
    @Input() public autoScroll = false;
    @Output() public timeStampClick = new EventEmitter<number>();
    public tsToHighlight: number;
    public timeStampNotes: {
        timestamp: string;
        notes: string[];
    }[];
    constructor() { }

    public ngOnInit() {
        this.updateTsNotes();
        this.updateTsToHighlight();
    }
    public ngOnChanges(changes: { note: any; currentTimeStamp: any; }) {
        if (changes.note) this.updateTsNotes();
        if (changes.currentTimeStamp) this.updateTsToHighlight();
    }
    public updateTsToHighlight() {
        // update time stamp to highlight
        const timeStamps = this.timeStampNotes.map(ts => Number(ts.timestamp));
        const old = this.tsToHighlight;
        this.tsToHighlight = timeStamps[0];
        for (const ts of timeStamps) {
            if (ts <= this.currentTimeStamp) {
                this.tsToHighlight = ts;
            } else {
                break;
            }
        }
        const changed = old !== this.tsToHighlight;
        if (changed && this.autoScroll) {
            const highlighted = document.querySelector(`#timestamp-${this.tsToHighlight}`);
            if (highlighted) {
                highlighted.scrollIntoView({
                    behavior: "smooth",
                    block: "center"
                });
            }
        }
    }
    private updateTsNotes() {
        // update time stamp notes
        if (!this.note) {
            this.timeStampNotes = [];
        } else {
            this.timeStampNotes = Object.keys(this.note.notes).map(ts => ({
                timestamp: ts,
                notes: this.note.notes[Number(ts)],
            }));
        }
    }
    public onTimeStampClick(ts: number) {
        this.timeStampClick.next(ts);
    }
}
