import { Spreaker } from "./app/MediaProviders/Spreaker";
import { MediaProvider } from "./MediaProvider";
import { MatSnackBar } from "@angular/material";
import { HttpClient } from "@angular/common/http";
import { AudioFileProvider } from "./app/MediaProviders/AudioFileProvider";
import { FileUrl } from "./app/MediaProviders/FileUrl";
import { YouTube } from "./app/MediaProviders/YouTube";
import { SoundCloud } from "./app/MediaProviders/SoundCloud";

export function MediaProviderFactory(name: string, http: HttpClient, snackbar: MatSnackBar): MediaProvider {
    switch (name) {
        case "Spreaker":
            return new Spreaker(http, snackbar);
            break;
        case "file":
            return new AudioFileProvider();
            break;
        case "File URL":
            return new FileUrl();
            break;
        case "YouTube":
            return new YouTube(http);
            break;
        case "SoundCloud":
            return new SoundCloud();
            break;
        default:
            break;
    }
}
