import { Component, OnInit, Input, ViewChild, OnChanges } from "@angular/core";
import { MatSort, MatTableDataSource, MatSnackBar } from "@angular/material";
import { NotesDatabaseService } from "../notes-database.service";
import { Spreaker } from "../MediaProviders/Spreaker";
import { YouTube } from "../MediaProviders/YouTube";
import { HttpClient } from "@angular/common/http";
import { FileUrl } from "../MediaProviders/FileUrl";
import { SoundCloud } from "../MediaProviders/SoundCloud";

@Component({
    selector: "app-notes-table",
    templateUrl: "./notes-table.component.html",
    styleUrls: ["./notes-table.component.less"]
})
export class NotesTableComponent implements OnInit {
    @Input() public notes: MatTableDataSource<NoteRecord>;
    public columnsToDisplay = ["percentComplete", "title", "folder", "dateUpdated", "manage"];
    @ViewChild(MatSort) private sort: MatSort;

    public mediaProviders = {
        Spreaker: new Spreaker(this.http, this.snackbar),
        YouTube: new YouTube(this.http),
        SoundCloud: new SoundCloud(),
        "File URL": new FileUrl(),
    };

    constructor(
        private notesService: NotesDatabaseService,
        private snackbar: MatSnackBar,
        private http: HttpClient,
    ) { }
    public ngOnInit() {
        this.notes.sort = this.sort;
    }

    public deleteNote(note: NoteRecord) {
        if (!confirm(`Are you sure you want to delete note ${note.title} ?`)) {
            return;
        }
        this.notesService.deleteNote(note).subscribe(() => {
            this.snackbar.open(`🗑 "${note.title}" deleted`, null, { duration: 3000 });
        });
    }
}
