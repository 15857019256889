import { MediaProvider } from "src/MediaProvider";
import { PlayerController } from "src/PlayerController";
import { AudioPlayerController } from "./AudioFileProvider";

export class FileUrl implements MediaProvider {
    public name = "File URL";
    public bannerImg: string;
    public iconImg: string;
    public showInteractionWarning = false;
    public createPlayerController(element: HTMLAudioElement): PlayerController {
        return new AudioPlayerController(element);
    }
}
