import { AngularFirestore } from "@angular/fire/firestore";
import { map, switchMap, tap } from "rxjs/operators";
import { Observable, from } from "rxjs";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireStorage } from "@angular/fire/storage";

/**
 * A service to assist with managing notes of a user.
 */
@Injectable()
export class NotesDatabaseService {
    /** The base collection name */
    private basePath = "users";
    /** The name of the collection in the user store that contains their notes. */
    private userNotesCollName = "notes";
    /** The user ID */
    private userId = this.afAuth.user.pipe(map(u => (u ? u.uid : null)));
    /** The bucket storage name for audio files */
    public storageBucketName = "audio";
    /** Yousef's user ID (used for safe debugging) */
    private yousefUserId = "Sv1PHNWAQueIWSQQC5RhvS3QZ6z2";
    /** Whether the current user is Yousef (for debugging). */
    public isYousef = this.userId.pipe(
        map(u => u === this.yousefUserId),
        tap(() => console.error("isYousef was called")),
    );

    constructor(
        private afDatabase: AngularFirestore,
        private afAuth: AngularFireAuth,
        private afStorage: AngularFireStorage
    ) {
    }

    public publishNote(note: NoteRecord) {
        return this.userId.pipe(switchMap(userId => {
            const publish: PublishedNoteRecord = {
                ...note,
                ownerId: userId,
                dateUpdated: new Date() as any,
            };
            return this.afDatabase
                .collection("published-notes")
                .doc<PublishedNoteRecord>(note.id)
                .set(publish);
        }));
    }

    public deletePublishedNote(note: NoteRecord) {
        return this.afDatabase
            .collection("published-notes")
            .doc<PublishedNoteRecord>(note.id)
            .delete();
    }
    public getPublished(noteId: string) {
        return this.afDatabase.collection("published-notes").doc<PublishedNoteRecord>(noteId);
    }

    /**
     * Deletes a note and its audio file from the server
     * @param note The note to delete
     */
    public deleteNote(note: NoteRecord) {
        return this.userId.pipe(
            switchMap(userId => {
                const deleteProm = this.afDatabase
                    .doc(`${this.basePath}/${userId}/${this.userNotesCollName}/${note.id}`)
                    .delete();
                deleteProm.then(() => {
                    if (!note.media) {
                        this.afStorage.ref(`${this.storageBucketName}/${userId}/${note.folder}/${note.id}`).delete();
                    }
                });
                return from(deleteProm);
            })
        );
    }

    /**
     * The user's notes
     */
    public get userNotes() {
        return this.userId.pipe(
            switchMap(userId => {
                const path = `${this.basePath}/${userId}/${this.userNotesCollName}`;
                return this.afDatabase
                    .collection<NoteRecord>(path)
                    .snapshotChanges()
                    .pipe(map(x => x.map(y => ({ id: y.payload.doc.id, ...y.payload.doc.data() }))));
            })
        );
    }

    public getFolderNotes(folderName: string): Observable<NoteRecord[]> {
        if (!folderName) {
            return this.userNotes;
        }
        return this.userId.pipe(
            switchMap(userId => {
                return this.afDatabase
                    .collection<NoteRecord>(`${this.basePath}/${userId}/notes`, ref => {
                        return ref.where("folder", "==", folderName);
                    })
                    .snapshotChanges()
                    .pipe(map(x => x.map(y => ({ id: y.payload.doc.id, ...y.payload.doc.data() }))));
            })
        );
    }

    /**
     * The user's folders
     */
    public get userFolders() {
        return this.userNotes.pipe(x => x.pipe(map(y => y.map(z => z.folder))));
    }

    /**
     * Gets a user's note
     * @param noteId the id of the note
     */
    public getUserNote(noteId: string): Observable<NoteRecord> {
        return this.userId.pipe(
            switchMap(userId => {
                const path = `${this.basePath}/${userId}/${this.userNotesCollName}/${noteId}`;
                return this
                    .afDatabase
                    .doc<NoteRecord>(path)
                    .valueChanges()
                    .pipe(map(note => {
                        note.id = noteId;
                        if (note.timeStampDelay == null) {
                            note.timeStampDelay = 0;
                        }
                        return note;
                    }));
            })
        );
    }

    /**
     * Gets the audio url of a note
     * @param note The note
     * @param userId The owner of the note. If null, uses current authenticated user to create path for the file url.
     */
    public getAudioFileUrlForNote(note: Note, userId?: string): Observable<string> {
        return this.userId.pipe(
            switchMap(loggedInUserId => {
                const storagePath = `${this.storageBucketName}/${userId || loggedInUserId}/${note.folder}/${note.id}`;
                return this.afStorage.ref(storagePath).getDownloadURL();
            })
        );
    }

    /**
     * Creates a note in the database.
     * @param note The note
     * @param audio The audio file
     * @returns the upload reference task.
     */
    public createNote(note: NoteRecord, audio: File) {
        return this.userId.pipe(
            switchMap(userId => {
                const docPath = `${this.basePath}/${userId}/${this.userNotesCollName}`;
                const addTask = from(this.afDatabase.collection(`${docPath}`).add(note)).pipe(
                    map(ref => {
                        const storagePath = `${this.storageBucketName}/${userId}/${note.folder}/${ref.id}`;
                        if (audio) { return this.afStorage.ref(storagePath).put(audio); }
                    })
                );
                return addTask;
            })
        );
    }

    /**
     * Updates a note record in the database
     * @param updatedNote the update note
     */
    public updateNote(updatedNote: NoteRecord) {
        console.assert(updatedNote.id !== undefined);
        updatedNote = { ...updatedNote }; // create a copy because the id gets deleted before inserting into the db
        return this.userId.pipe(
            switchMap(userId => {
                const docPath = `${this.basePath}/${userId}/${this.userNotesCollName}/${updatedNote.id}`;
                updatedNote.dateUpdated = new Date() as any;
                delete updatedNote.id; // deleted because ID shouldn't be stored in the db. firebase takes care of ids.
                return this.afDatabase.doc(docPath).set(updatedNote);
            })
        );
    }
}
