import { Observable, ReplaySubject, Subject } from "rxjs";
import { Input } from "@angular/core";
import { Episode } from "./MediaProvider";
let onAPILoad;
/**
 * The super class for player controllers.
 * This just imports the third party player script.
 */
export abstract class PlayerController {
    @Input() public episodeId: string;
    /** Runs when API (via a <script> tag that is automatically created) is ready */
    protected onAPILoad = new ReplaySubject<void>();
    public oncanplay = new ReplaySubject<void>();
    public episode = new ReplaySubject<Partial<Episode>>();

    constructor(scriptUrl?: string) {
        onAPILoad = this.onAPILoad;
        if (scriptUrl) {
            const scriptTag = document.querySelector(`script[src='${scriptUrl}']`);
            if (scriptTag) { scriptTag.parentElement.removeChild(scriptTag); }
            const firstScriptTag = document.getElementsByTagName("script")[0];
            const script = document.createElement("script");
            script.src = scriptUrl;
            firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
            script.onload = () => {
                onAPILoad.next();
                this.onAPILoad.next();
            };
        } else {
            {
                onAPILoad.next();
                this.onAPILoad.next();
            }
        }
    }
    /** The current player time */
    public abstract currentTime: Observable<number>;
    /** The duration of the player time */
    public abstract duration: Observable<number>;
    /** Plays the media */
    public abstract play(): void;
    /** Pauses the media */
    public abstract pause(): void;
    /** Toggles the play state */
    public abstract toggle(): void;
    /** Sets the current time of the player */
    public abstract goTo(seconds: number): void;
}
