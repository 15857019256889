import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MediaProvider, Episode } from "src/MediaProvider";
import { NgForm } from "@angular/forms";
import { MatSnackBar } from "@angular/material";

@Component({
    selector: "app-media-provider-searcher",
    templateUrl: "./media-provider-searcher.component.html",
    styleUrls: ["./media-provider-searcher.component.less"]
})
export class MediaProviderSearcherComponent implements OnInit {
    @Output() public save = new EventEmitter();
    @Input() public mediaProvider: MediaProvider;
    @Input() public folders: string[];
    @Input() public selectedFolder: string;
    public searchResults: Episode[];
    public selected: Partial<Episode> = {};
    public title: string;

    constructor(private sb: MatSnackBar) { }
    public ngOnInit(): void { }
    public onEnter(event: Event, element: HTMLInputElement) {
        event.preventDefault();
        this.selected = {};
        this.searchResults = [];
        this.mediaProvider.search(element.value).subscribe(x => {
            this.searchResults = x;
            if (x.length === 0) { this.sb.open("⚠️ No search results.", null, { duration: 2000 }); }
        });
    }
    public onSelect(event) {
        this.selected = {}; // to make player recreate itself
        setTimeout(() => {
            const episode = event.option.value as Episode;
            this.selected = episode;
            this.title = episode.title;
            this.selectedFolder = episode.show.title;
        }, 0);
    }
    public onSubmit(form: NgForm) {
        if (form.invalid) { return; }
        this.save.emit(this);
    }
}
