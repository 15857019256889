import { Component, OnInit, ViewChild, Input, ElementRef, OnChanges, Output, EventEmitter } from "@angular/core";
import { Subject } from "rxjs";
import { MatSnackBar } from "@angular/material";
import { Spreaker } from "../MediaProviders/Spreaker";
import { HttpClient } from "@angular/common/http";
import { PlayerController } from "src/PlayerController";
import { GeneralPlayerComponent } from "src/models/GeneralPlayerComponent";
import { Episode } from "src/MediaProvider";

@Component({
    selector: "app-spreaker-player",
    templateUrl: "./spreaker-player.component.html",
    styleUrls: ["./spreaker-player.component.less"]
})
export class SpreakerPlayerComponent implements OnInit, OnChanges, GeneralPlayerComponent {
    @Output() public episode = new EventEmitter<Partial<Episode>>();
    @Input() public episodeId: string;
    @ViewChild("player") private player: ElementRef;
    private spreaker: Spreaker;
    public oncanplay = new Subject<void>();
    public controller: PlayerController;
    constructor(private snackbar: MatSnackBar, private http: HttpClient) { }

    public ngOnInit() {
        this.spreaker = new Spreaker(this.http, this.snackbar);
        this.controller = this.spreaker.createPlayerController(this.player.nativeElement);
        this.controller.episode.subscribe((episode) => this.episode.next(episode));
        this.controller.oncanplay.subscribe(() => {
            this.oncanplay.next();
        });
    }
    public ngOnChanges() {
        this.ngOnInit();
    }
}
