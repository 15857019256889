import { MediaProvider, Episode } from "src/MediaProvider";
import { Observable, ReplaySubject, from } from "rxjs";
import { PlayerController } from "src/PlayerController";
import { HttpClient } from "@angular/common/http";

declare const SC;

class SoundCloudController extends PlayerController {
    public oncanplay = new ReplaySubject<void>();
    private widget: any;
    public currentTime: Observable<number>;
    public duration: Observable<number>;
    constructor(playerElement: HTMLIFrameElement) {
        super();
        this.onAPILoad.subscribe(() => {
            this.widget = SC.Widget(playerElement);
            playerElement.addEventListener("load", () => {
                this.widget.bind(SC.Widget.Events.READY, event => {
                    this.oncanplay.next();
                    this.widget.getCurrentSound(item => {
                        const episode = (<Episode>{
                            id: item.id,
                            title: item.title,
                            datePublished: new Date(item.last_modified),
                            duration: Math.floor(item.duration / 1000),
                            image: item.user.avatar_url,
                            description: item.description,
                            show: { title: item.user.username }
                        });
                        this.episode.next(episode);
                    });
                });
            });

            this.currentTime = Observable.create(observer => {
                this.widget.getPosition(p => observer.next(Math.round(p / 1000)));
            });
            this.duration = Observable.create(observer => {
                this.widget.getDuration(d => observer.next(d / 1000));
            });
        });
    }
    public play() {
        this.widget.play();
    }
    public pause() {
        this.widget.pause();
    }
    public toggle() {
        this.widget.toggle();
    }
    public goTo(seconds: number) {
        this.widget.seekTo(seconds * 1000);
    }
}
export class SoundCloud implements MediaProvider {
    public name = "SoundCloud";
    public iconImg = "/assets/images/soundcloud-icon.png";
    public bannerImg = "/assets/images/soundcloud-banner.svg";
    public showInteractionWarning = false;
    constructor() { }
    public createPlayerController(element: HTMLIFrameElement) {
        return new SoundCloudController(element);
    }
    public search?(query: string): Observable<Episode[]> {
        throw new Error(
            "SoundCloud has not given me permission to use their search API since I'm using their key. Waiting."
        );
        SC.initialize({ client_id: "wBZbydoVnaCcBic8oQsle3MlRFFnd2H4" });
        return from(SC
            .get("/tracks", { q: query })
            .then(function (tracks) {
                return tracks.map(item => (<Episode>{
                    title: item.title,
                    datePublished: new Date(item.last_modified),
                    id: item.id,
                    duration: Math.floor(item.duration / 1000),
                    image: item.user.avatar_url,
                    description: item.description,
                    show: { title: item.user.username }
                }));
            }));
    }
}
